<template>
  <div class="factoryUnitForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序值', trigger: 'blur' }]"
        prop="sort"
      >
        <v-input
          placeholder="请输入排序值"
          v-model="form.sort"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="单位名称"
        :rules="[
          { required: true, message: '请输入单位名称', trigger: 'blur' },
        ]"
        prop="name"
      >
        <v-input
          placeholder="请输入单位名称"
          v-model="form.name"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="联系人"
        :rules="[{ required: true, message: '请输入联系人', trigger: 'blur' }]"
        prop="contactName"
      >
        <v-input
          placeholder="请输入联系人"
          v-model="form.contactName"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        :rules="[
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: validatePhone, trigger: 'change' },
        ]"
        prop="contactMobile"
      >
        <div class="form-item">
          <v-input
            :disabled="isEdit"
            placeholder="请输入手机号"
            v-model="form.contactMobile"
            @blur="handelInputBlur"
          ></v-input>
          <v-button
            v-if="form.id"
            text="编辑"
            style="margin-left: 10px"
            @click="handelClick"
          ></v-button>
        </div>
      </el-form-item>
      <el-form-item
        label="企业图片"
        :rules="[
          { required: true, message: '请上传企业图片', trigger: 'blur' },
        ]"
        prop="picture"
      >
        <v-upload
          :limit="1"
          :imgUrls.sync="form.picture"
          tips="建议上传345px*165px图片"
        />
      </el-form-item>

      <el-form-item
        label="简介"
        :rules="[{ required: true, message: '请输入简介', trigger: 'blur' }]"
        prop="description"
      >
        <v-input
          placeholder="请输入简介"
          :width="300"
          v-model="form.description"
          type="textarea"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { setAESCbcEncrypt } from "@/utils/common";
import { mobileRegular } from "@/utils/regular";
import { addBatUnit, getBatUnitDetailURL, batUnitEdit } from "./api";

export default {
  name: "goodsEdit",
  data() {
    return {
      submitConfig: {
        queryUrl: getBatUnitDetailURL,
        submitUrl: addBatUnit,
      },
      form: {
        id: "",
        name: "",
        sort: 0,
        contactName: "",
        contactMobile: "",
        picture: "",
        description: "",
      },
      isEdit: false,
    };
  },
  mounted() {
    const { id, communityId } = this.$route.query;
    this.form.id = id;
    if (id !== undefined) {
      this.isEdit = true;
      this.$refs.formPanel.getData({ id });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    handelInputBlur() {
      if (this.form.id) {
        const params = {
          id: this.form.id,
          contactMobile: setAESCbcEncrypt(this.form.contactMobile),
        };
        this.$axios.post(batUnitEdit, params).then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg);
            this.isEdit = true;
          }
        });
      }
    },
    handelClick() {
      this.form.contactMobile=""
      this.isEdit = false;
    },
    submitSuccess(data) {
      return true;
    },

    submitBefore() {
      if (this.form.id) {
        delete this.form.contactMobile;
      } else {
        this.form.contactMobile = setAESCbcEncrypt(this.form.contactMobile);
      }
      return true;
    },

    validatePhone(rule, value, callback) {
      if (!this.form.id) {
        let regExp = mobileRegular;
        if (!regExp.test(value)) {
          callback(new Error("您填写手机号码格式不正确"));
        } else {
          callback();
        }
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.factoryUnitForm {
  box-sizing: border-box;
  height: 100%;

  .form-item {
    display: flex;
  }
  /deep/ .el-textarea {
    height: 150px !important;
    textarea {
      height: 100%;
    }
  }
}
</style>
<style lang="less">
.factoryUnitForm {
}
</style>
